import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { connect } from "react-redux";
import moment from "moment-timezone";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import addCyrullicFont from "../../utilities/cyrillic-roboto-fonts";

import {
  getReviewList,
  updateReview,
} from "../../stores/actions/test.actions.types";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { successMessage } from "../../utilities/notification";
import { uploadFile, removeFile } from "../../stores/actions/file.actions.type";

function stripHtml(html) {
  return html.replace(/<[^>]*>?/gm, "");
}

const GetReview = (props) => {
  const [reviewList, setReviewList] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [questionArray, setQuestionsArray] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [formData, setFormData] = useState(null);
  const [isPreviewable, setIsPreviewable] = useState(false);
  const [showUploadReview, setShowUploadReview] = useState(false);
  const [uploadingReview, setUploadingReview] = useState(false);

  useEffect(() => {
    getReviewList();
  }, []);

  const getReviewList = () => {
    const callback = (data) => {
      if (data.success) {
        setReviewList(data.data.data);
      }
    };
    props.getReviewList({ data: {}, callback });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "User Email",
        header: "User Email",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row?.original?.userId?.email}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Test Name",
        header: "Test Name",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row?.original?.testId?.name}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Total Question",
        header: "Total Question",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row?.original?.testId?.totalQuestion}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Total Time",
        header: "Total Time",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row?.original?.testId?.totalTime}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Updated",
        header: "Updated",
        Cell: ({ row }) => {
          return (
            <>
              <span>{moment(row?.original?.updatedAt).format("LLL")}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Status",
        header: "Status",
        Cell: ({ row }) => {
          return (
            <>
              <span>
                {row?.original?.isPreviewRequestCompleted
                  ? "Completed"
                  : "Pending"}
              </span>
            </>
          );
        },
      },
      {
        accessorKey: "Grade",
        header: "Grade",
        Cell: ({ row }) => {
          const isCompleted = row?.original?.isPreviewRequestCompleted;
          const questions = row?.original?.questions;
          let totalQuestionsMark = 0;
          let totalAnswerMark = 0;
          let markGrade = "";
          let numberObtainedInPercentage = 0;

          if (isCompleted) {
            for (let question of questions) {
              totalQuestionsMark += question?.questionMark
                ? parseInt(question?.questionMark)
                : 0;
              totalAnswerMark += question?.questionMark
                ? parseInt(question?.answerMark)
                : 0;
            }

            numberObtainedInPercentage =
              (totalAnswerMark / totalQuestionsMark) * 100;

            if (numberObtainedInPercentage <= 30) {
              markGrade = "Weak 2";
            } else if (
              numberObtainedInPercentage > 30 &&
              numberObtainedInPercentage < 41
            ) {
              markGrade = "Average";
            } else if (
              numberObtainedInPercentage >= 41 &&
              numberObtainedInPercentage < 62
            ) {
              markGrade = "Good";
            } else if (
              numberObtainedInPercentage >= 62 &&
              numberObtainedInPercentage < 84
            ) {
              markGrade = "Mn. Good";
            } else if (
              numberObtainedInPercentage >= 84 &&
              numberObtainedInPercentage < 95
            ) {
              markGrade = "Excellent";
            } else if (numberObtainedInPercentage >= 95) {
              markGrade = "Excellent 6";
            }
          }

          return (
            <>
              <span>{!isCompleted ? "Pending" : markGrade}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Preview",
        header: "Preview",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                className="btn btn-primary btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                onClick={() => {
                  setQuestionsArray(row?.original?.questions);
                  setSelectedId(row?.original?._id);
                  setModalOpen(true);
                  setFormData(row?.original);
                  if (row?.original?.questions?.length > 0) {
                    for (const item of row?.original?.questions) {
                      if (item.questionType === "Text") {
                        setIsPreviewable(true);
                      }
                    }
                  }
                }}
              >
                <i className="fa fa-eye"></i>
              </button>
            </div>
          );
        },
      },
      {
        accessorKey: "Upload Preview",
        header: "Upload Preview",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                className="btn btn-primary btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                onClick={() => {
                  setShowUploadReview(true);
                  setQuestionsArray(row?.original?.questions);
                  setSelectedId(row?.original?._id);
                  setFormData(row?.original);
                }}
              >
                <i className="fa fa-upload"></i>
              </button>
            </div>
          );
        },
      },
      {
        accessorKey: "Download",
        header: "Download",
        Cell: ({ row }) => {
          return (
            <div>
              <button
                className="btn btn-primary btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
                onClick={() => {
                  handleDownloadReview(row?.original);
                }}
              >
                <i className="fa fa-download"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const onUpdate = () => {
    let previewComplete = true;

    for (let item of questionArray) {
      if (item.questionType === "Text" && !item.review) {
        previewComplete = false;
      }
    }

    const value = {
      id: selectedId,
      formData: {
        ...formData,
        questions: questionArray,
        isPreviewRequestCompleted: previewComplete,
        packageId: formData.userPackageId,
        userId: formData.userId._id,
      },
    };

    const callback = (data) => {
      if (data.success) {
        successMessage("Review updated successfully");
        setModalOpen(false);
        setQuestionsArray([]);
        setSelectedId("");

        const callback = (data) => {
          if (data.success) {
            setReviewList(data.data.data);
          }
        };
        props.getReviewList({ data: {}, callback });
      }
    };
    setModalOpen(false);
    setIsPreviewable(false);
    props.updateReview({ data: value, callback });
  };

  const handleReviewChange = (event, index) => {
    let array = [...questionArray];
    array[index].review = event.target.value.trimStart();
    setQuestionsArray([...array]);
  };

  const handleMarkChange = (event, index) => {
    let array = [...questionArray];
    array[index].answerMark = event.target.value.trimStart();
    setQuestionsArray([...array]);
  };

  const handleDownloadReview = (data) => {
    const questions = data.questions;
    let totalQuestionsMark = 0;
    let totalAnswerMark = 0;
    let markGrade = "";
    let numberObtainedInPercentage = 0;
    let tempTotalSelectQuestionMark = 0;
    let tempTotalFreeQuestionMark = 0;
    let tempTotalSelectAnswerMark = 0;
    let tempTotalFreeAnswerMark = 0;
    let tempTotalSelectQuestion = 0;
    let tempTotalFreeQuestion = 0;
    let item = 1;

    const body = [];
    for (const question of questions) {
      body.push([
        item,
        stripHtml(question.questionTitle),
        question.userAnswer[0],
        question.review,
        question.questionMark,
        question.answerMark,
      ]);
      item++;

      totalQuestionsMark += question?.questionMark
        ? parseInt(question?.questionMark)
        : 0;
      totalAnswerMark += question?.questionMark
        ? parseInt(question?.answerMark)
        : 0;

      if (question.questionType === "Text") {
        tempTotalFreeQuestion += 1;
        tempTotalFreeQuestionMark += parseInt(question?.questionMark);
        tempTotalFreeAnswerMark += parseInt(question?.answerMark);
      } else {
        tempTotalSelectQuestion += 1;
        tempTotalSelectQuestionMark += parseInt(question?.questionMark);
        tempTotalSelectAnswerMark += parseInt(question?.answerMark);
      }
    }

    numberObtainedInPercentage = (totalAnswerMark / totalQuestionsMark) * 100;

    if (numberObtainedInPercentage <= 30) {
      markGrade = "Weak";
    } else if (
      numberObtainedInPercentage > 30 &&
      numberObtainedInPercentage <= 41
    ) {
      markGrade = "Average";
    } else if (
      numberObtainedInPercentage > 41 &&
      numberObtainedInPercentage <= 62
    ) {
      markGrade = "Good";
    } else if (numberObtainedInPercentage > 62) {
      markGrade = "Excellent";
    }

    const doc = new jsPDF();
    addCyrullicFont(doc);
    doc.setFont("Roboto");

    doc.setFontSize(22);
    doc.text(15, 25, `Test Name: ${data?.testId?.name}`);
    doc.setFontSize(13);
    doc.text(15, 35, `Subject: ${data?.subject}`);
    doc.text(15, 45, `Select Type Question: ${tempTotalSelectQuestion}`);
    doc.text(15, 55, `Free Type Question: ${tempTotalFreeQuestion}`);
    doc.text(15, 65, `Total Question: ${questions.length}`);
    doc.text(
      15,
      70,
      "========================================================="
    );
    doc.text(
      15,
      80,
      `Select Type Question Mark: ${tempTotalSelectQuestionMark}`
    );
    doc.text(15, 90, `Free Type Question Mark: ${tempTotalFreeQuestionMark}`);
    doc.text(15, 100, `Total Question Mark: ${totalQuestionsMark}`);
    doc.text(
      15,
      105,
      "========================================================="
    );
    doc.text(
      15,
      115,
      `Select Type Question Acquired Mark: ${tempTotalSelectAnswerMark}`
    );
    doc.text(
      15,
      125,
      `Free Type Question Acquired Mark: ${tempTotalFreeAnswerMark}`
    );
    doc.text(15, 135, `Total Acquired Mark: ${totalAnswerMark}`);
    doc.text(
      15,
      145,
      `Mark Percentage: ${(
        (totalAnswerMark / totalQuestionsMark) *
        100
      ).toFixed(2)}%`
    );
    doc.text(15, 155, `Grade: ${markGrade}`);
    autoTable(doc, { html: "#my-table" });

    autoTable(doc, {
      head: [
        [
          "No.",
          "Question Title",
          "Question Answer",
          "Question Review",
          "Question Mark",
          "Acquired Mark",
        ],
      ],
      body,
      styles: {
        font: "Roboto",
        fontStyle: "normal",
        fontSize: 12,
      },
      headStyles: {
        font: "Roboto",
        fontStyle: "normal",
        fontSize: 12,
      },
      bodyStyles: {
        font: "Roboto",
        fontStyle: "normal",
        fontSize: 12,
      },
      startY: 170,
    });

    doc.save("review.pdf");
  };

  const onChangeUploadFile = (event) => {
    setUploadingReview(true);

    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const fileFormData = new FormData();
      fileFormData.append("file", event.target.files[0]);

      const callback = (data) => {
        if (data.success) {
          const reviewFile = data.data.data.Location;

          if (formData?.reviewFile) {
            const key = formData?.reviewFile?.substring(
              formData?.reviewFile?.lastIndexOf("/") + 1
            );

            const innerCallback = (data) => {
              if (data.success) {
              }
            };
            props.removeFile({ key, callback: innerCallback });
          }

          const value = {
            id: selectedId,
            formData: {
              ...formData,
              questions: questionArray,
              packageId: formData.userPackageId,
              userId: formData.userId._id,
              reviewFile,
            },
          };

          const callback = (data) => {
            if (data.success) {
              successMessage("Review uploaded successfully");
              setShowUploadReview(false);
              setQuestionsArray([]);
              setSelectedId("");
              setUploadingReview(false);

              const callback = (data) => {
                if (data.success) {
                  setReviewList(data.data.data);
                }
              };
              props.getReviewList({ data: {}, callback });
            }
          };

          props.updateReview({ data: value, callback });
        }
      };

      props.uploadFile({ data: fileFormData, callback });
    }
  };

  return (
    <>
      {reviewList ? (
        <MaterialReactTable
          columns={columns}
          data={reviewList}
          muiTableHeadCellProps={{
            align: "center",
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          defaultColumn={{
            maxSize: 50,
            minSize: 50,
          }}
          enableColumnResizing
          columnResizeMode="onChange"
        />
      ) : null}
      <Modal
        show={modalOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-contents"
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setModalOpen(false);
            setIsPreviewable(false);
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {"Review Details"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <div>
            {isPreviewable ? (
              questionArray?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item?.questionType === "Text" && (
                      <div className="card px-2 py-3 mt-2">
                        <label className="mb-2 mt-2">Question Title:</label>

                        {item.questionTitle ? (
                          <div
                            className="mt-2"
                            dangerouslySetInnerHTML={{
                              __html: item.questionTitle,
                            }}
                          ></div>
                        ) : null}

                        <label className="mb-2 mt-2">Sample Answer:</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          value={
                            item.textTypeQuestionAnswer
                              ? item.textTypeQuestionAnswer
                              : ""
                          }
                          disabled={true}
                        />

                        <label className="mb-2 mt-2">Question Answer:</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          value={item.userAnswer ? item.userAnswer[0] : ""}
                          disabled={true}
                        />

                        <label className="mb-2 mt-2">Question Mark:</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          value={item.questionMark ? item.questionMark : ""}
                          disabled={true}
                        />

                        <label className="mb-2 mt-2">Acquired Mark:</label>
                        <input
                          type="number"
                          className="form-control mt-2"
                          value={item.answerMark ? item.answerMark : ""}
                          onChange={(event) => handleMarkChange(event, index)}
                        />

                        <label className="mb-2 mt-2">Question Review:</label>
                        <input
                          type="text"
                          className="form-control mt-2"
                          value={item.review ? item.review : ""}
                          onChange={(event) => handleReviewChange(event, index)}
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <p class="text-center">Nothing to preview</p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setModalOpen(false);
              setIsPreviewable(false);
            }}
          >
            Close
          </Button>
          <Button onClick={onUpdate} disabled={!isPreviewable}>
            {"Update"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUploadReview}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-contents"
      >
        <Modal.Header
          closeButton
          onClick={() => {
            setShowUploadReview(false);
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {"Upload Review"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <div>
            {uploadingReview ? (
              <h4
                style={{
                  display: "block",
                  textAlign: "center",
                }}
              >
                Uploading...
              </h4>
            ) : (
              <input
                type="file"
                className="form-control"
                onChange={onChangeUploadFile}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShowUploadReview(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
  getReviewList,
  updateReview,
  uploadFile,
  removeFile,
})(GetReview);
