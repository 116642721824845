import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { connect } from "react-redux";
import {
  getAllClass,
  deleteClass,
  updateClass, 
  addClass
  } from "../../stores/actions/class.actions.types"
  import moment from "moment-timezone";
import Swal from "sweetalert2";
  import Modal from 'react-bootstrap/Modal';
  import Button from 'react-bootstrap/Button';
import { errorMessage } from "../../utilities/notification";

const Class = (props) => {
const [classData , setClassData] = useState()
const [updateModalOpen , setUpdateModalOpen] = useState(false)
const [name, setName] =useState("")
const [selectId , setSelectId] = useState()

useEffect(() => {
  getClassList();
}, []);


const getClassList = () => {
  const callback = (data) => {
    if (data.success) {
      setClassData( data.data.data)
      }
    }
  props.getAllClass({ data: {}, callback });
};


const deleteClass = (id) => {
  const callback = (data) => {
    if (data.success) {
      getClassList()
      }
    }
  props.deleteClass({ data: id, callback });
};


const updateClass = () => {
  if(!name){
    errorMessage("Please enter name")
    return
  }
  const value = {id:selectId, name:name}
  const callback = (data) => {
    if (data.success) {
      setUpdateModalOpen(false)
      setName("")
      setSelectId()
      getClassList()
      }
    }
  props.updateClass({ data: value, callback });
};


const addClass = () => {
  if(!name){
    errorMessage("Please enter name")
    return
  }
  const value = { name:name}
  const callback = (data) => {
    if (data.success) {
      setUpdateModalOpen(false)
      setName("")
      setSelectId()
      getClassList()
      }
    }
  props.addClass({ data: value, callback });
};


const columns = useMemo(
  () => [
  
    {
      accessorKey: "Class",
      header: "Class",
      Cell: ({ row }) => {
        return (
          <>
            <span>{row.original.name}</span> 
          </>
        );
      },
    },
    {
      accessorKey: "Created",
      header: "Create",
      Cell: ({ row }) => {
        return (
          <>
            <span>{moment(row.original.created_at).format("LLL")}</span> 
          </>
        );
      },
    },
    {
      accessorKey: "Updated",
      header: "Update",
      Cell: ({ row }) => {
        return (
          <>
            <span>{moment(row.original.updatedAt).format("LLL")}</span> 
          </>
        );
      },
    },
    {
        accessorKey: "Edit",
        header: "Edit",
        Cell: ({ row }) => {
          return (
            <div>
              <button
              onClick={()=>{  setName(row.original.name)
                setSelectId(row.original._id)
                setUpdateModalOpen(true)}}
                className="btn btn-primary btn-sm editIconBtn"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <i className="fa fa-pencil-square"></i>
              </button>
            </div>
          );
        },
      },
    {
      accessorKey: "Remove",
      header: "Remove",
      Cell: ({ row }) => {
        return (
          <div>
          <button
          onClick={()=>Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if(result.isConfirmed){
              deleteClass(row.original._id)
            }
          })}
            className="btn btn-danger btn-sm editIconBtn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <i className="fas fa-trash"></i>
          </button>
        </div>
        );
      },
    },
  ],
  []
);
  return (
    <>
        <Button className="mb-3" onClick={()=>{
          setName()
          setSelectId()
          setUpdateModalOpen(true)}} >Add Class</Button>

     {classData ? (
          <MaterialReactTable
            columns={columns}
            data={classData}
            muiTableHeadCellProps={{
              align: "center",
            }}
            muiTableBodyCellProps={{
              align: "center",
            }}
            // enablePinning
            defaultColumn={{
              maxSize: 100,
              minSize: 100,
            }}
            enableColumnResizing
            columnResizeMode="onChange" //default
          />
        ) : null}

<Modal
show={updateModalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={()=>setUpdateModalOpen(false)} >
        <Modal.Title id="contained-modal-title-vcenter">
          {selectId ? "Update Class":"Add Class"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
     <input className="form-control  mb-2 mx-2" value={name} onChange={(e)=>setName(e.target.value)} placeholder="Enter name"/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{setUpdateModalOpen(false)}}>Close</Button>
        <Button onClick={()=> selectId ?  updateClass() :addClass()}>{selectId ?"Update":"Add"}</Button>

      </Modal.Footer>
    </Modal>
    </>
  )


          }

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
    getAllClass,
    deleteClass,
    addClass,
    updateClass
})(Class);


