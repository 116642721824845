import { all, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_CLASS,
  ADD_CLASS,
  UPDATE_CLASS,
  DELETE_CLASS,
} from "../actions/class.actions.types";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getAllClass({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/admin/getAllClass`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* addClass({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: `/admin/addClass`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* updateClass({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "patch",
    url: `/admin/updateClass/${data.id}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* deleteClass({ payload: { data, callback } }) {
  const payload = {
    method: "delete",
    url: `/admin/deleteClass/${data}`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: true,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Class() {
  yield all([
    takeLatest(GET_ALL_CLASS, getAllClass),
    takeLatest(ADD_CLASS, addClass),
    takeLatest(UPDATE_CLASS, updateClass),
    takeLatest(DELETE_CLASS, deleteClass),
  ]);
}

export default Class;
