import { all, put, takeLatest } from "redux-saga/effects";
import { userslice } from "../slices/user";
import { LOGIN } from "../actions/user.actions.types";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* login({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "post",
    url: "/admin/login",
  };

  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    yield put(userslice.actions.setAuthenticationToken(result.data?.token));
    yield put(userslice.actions.loginAction(result.data));
    if (callback) {
      callback({ success: true, data: null });
    }
  }
}

function* User() {
  yield all([takeLatest(LOGIN, login)]);
}

export default User;
