import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { connect } from "react-redux";
import { getAllUser } from "../../stores/actions/dashboard.actions.types";
import moment from "moment-timezone";

const User = (props) => {
  const [userData, setUserData] = useState();

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = () => {
    const callback = (data) => {
      if (data.success) {
        setUserData(data.data.data);
      }
    };
    props.getAllUser({ data: {}, callback });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "Name",
        header: "Name",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.name}</span>
            </>
          );
        },
      },
      {
        accessorKey: "E-mail",
        header: "E-mail",
        Cell: ({ row }) => {
          return (
            <>
              <span>{row.original.email}</span>
            </>
          );
        },
      },
      {
        accessorKey: "Created",
        header: "Create",
        Cell: ({ row }) => {
          return (
            <>
              <span>{moment(row.original.created_at).format("LLL")}</span>
            </>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      {userData ? (
        <MaterialReactTable
          columns={columns}
          data={userData}
          muiTableHeadCellProps={{
            align: "center",
          }}
          muiTableBodyCellProps={{
            align: "center",
          }}
          // enablePinning
          defaultColumn={{
            maxSize: 100,
            minSize: 100,
          }}
          enableColumnResizing
          columnResizeMode="onChange" //default
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
  getAllUser,
})(User);
