export const SIGN_IN = '/';
export const PROFILE = '/profile';
export const USER = '/dashboard';
export const PACKAGE = '/package';
export const SUBJECT = '/subject';
export const CLASS = '/class';
export const GRADE = '/grade';
export const TEST = '/test';
export const ADDTEST = '/add-test';
export const GETREVIEW = '/get-review';
export const GETMARK = '/get-mark';
export const UPDATETEST = '/update-test';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_SERVICE = '/terms-and-service';
