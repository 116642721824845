import { createAction } from "redux-actions";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const uploadFile = createAction(UPLOAD_FILE);

export const UPLOAD_MULTIPLE_FILE = "UPLOAD_MULTIPLE_FILE";
export const uploadMultipleFile = createAction(UPLOAD_MULTIPLE_FILE);

export const REMOVE_FILE = "REMOVE_FILE";
export const removeFile = createAction(REMOVE_FILE);
