import { all, takeLatest } from "redux-saga/effects";
import { GET_ALL_USER } from "../actions/dashboard.actions.types";
import httpClient from "../services/http.client";
import * as Effects from "redux-saga/effects";
const call = Effects.call;

function* getAlluser({ payload: { data, callback } }) {
  const payload = {
    data,
    method: "get",
    url: `/admin/getAllUsers`,
  };
  const { error, result } = yield call(httpClient, {
    payload: payload,
    isLoader: true,
    authorization: false,
  });

  if (error) {
    if (callback) {
      callback({ success: false, data: null });
    }
  } else {
    if (callback) {
      callback({ success: true, data: result });
    }
  }
}

function* Dashboard() {
  yield all([takeLatest(GET_ALL_USER, getAlluser)]);
}

export default Dashboard;
