import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {
  getAllTest,
  updateTest,
  deleteTest,
  addTest,
} from "../../stores/actions/test.actions.types";
import { getAllPackage } from "../../stores/actions/package.actions.types";
import Button from "react-bootstrap/Button";
import { errorMessage } from "../../utilities/notification";
import "react-form-builder2/dist/app.css";
import * as ROUTE from "../../routes/routesConstant";
import { useNavigate } from "react-router-dom";
import {
  uploadFile,
  uploadMultipleFile,
  removeFile,
} from "../../stores/actions/file.actions.type";
import indexPrefix from "../../utilities/indexPrefix";

const AddTest = (props) => {
  const [name, setName] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [selectPackageId, setSelectPackageId] = useState();
  const [totalQuestion, setTotalQuestion] = useState();
  const [totalTime, setTotalTime] = useState();
  const [questionArray, setQuestionsArray] = useState([]);
  const [multipleUploading, setMultipleUploading] = useState(false);
  const [getMarkAmount, setGetMarkAmount] = useState();
  const [getMarkStripePaymentId, setGetMarkStripePaymentId] = useState();
  const [getReviewAmount, setGetReviewAmount] = useState();
  const [getReviewStripePaymentId, setGetReviewStripePaymentId] = useState();
  const [editors, setEditors] = useState([]);
  const [editorsBottomShit, setEditorsBottomShit] = useState([]);
  const [editorsHint, setEditorsHint] = useState([]);

  const navigate = useNavigate();
  const inputFile = useRef(null);

  const QuestionTypeList = ["Text", "Radio", "Drag Drop"];
  const radioQuestionList = [
    "Multiple answer",
    "Question with description",
    "Question with image",
    "Question with image answer",
  ];

  const textQuestionList = ["Simple question", "Question with audio"];
  const dragAndDropQuestionList = ["Answer with select", "Answer with typing"];

  useEffect(() => {
    getPackageList();
  }, []);

  const getPackageList = () => {
    const callback = (data) => {
      if (data.success) {
        setPackageData(data.data.data);
      }
    };
    props.getAllPackage({ data: {}, callback });
  };

  const addTest = () => {
    let tempArr = [];

    for (let item of questionArray) {
      if (!item.isOptionImage) {
        tempArr.push({ ...item, options: item.multipleAnswer });
      } else {
        tempArr.push({ ...item });
      }
    }

    for (let item of tempArr) {
      if (item.questionType === "Drag Drop") {
        for (let [index, value] of item.dragDrop.entries()) {
          item.options.push(value.left);
          item.correctAnswer.push(value.right);
        }
      }
    }

    const value = {
      name: name,
      packageId: selectPackageId,
      totalQuestion: totalQuestion,
      totalTime: totalTime,
      getMarkAmount,
      getMarkStripePaymentId,
      getReviewAmount,
      getReviewStripePaymentId,
      questions: tempArr,
    };
    const callback = (data) => {
      if (data.success) {
        navigate(ROUTE.TEST);
      }
    };
    props.addTest({ data: value, callback });
  };

  const onCheckFields = () => {
    if (!name) {
      errorMessage("Please enter name");
    } else if (!selectPackageId) {
      errorMessage("Please select package");
    } else if (!totalQuestion) {
      errorMessage("Please enter total question");
    } else if (!totalTime) {
      errorMessage("Please enter total time ");
    } else if (getMarkStripePaymentId && !getMarkAmount) {
      errorMessage("Please enter get mark payment amount");
    } else if (getMarkStripePaymentId && getMarkAmount == 0) {
      errorMessage("Get mark payment amount is greater than 0");
    } else if (getMarkAmount && isNaN(getMarkAmount)) {
      errorMessage("Please enter get mark payment amount as a numeric value");
    } else if (getMarkAmount && !getMarkStripePaymentId) {
      errorMessage("Please enter get mark payment stripe id ");
    } else if (getReviewStripePaymentId && !getReviewAmount) {
      errorMessage("Please enter get review payment amount");
    } else if (getReviewStripePaymentId && getReviewAmount == 0) {
      errorMessage("Get review payment amount is greater than 0");
    } else if (getReviewAmount && isNaN(getReviewAmount)) {
      errorMessage("Please enter get review payment amount as a numeric value");
    } else if (getReviewAmount && !getReviewStripePaymentId) {
      errorMessage("Please enter get review payment stripe id");
    } else if (questionArray.length == 0) {
      errorMessage("Please select question");
    } else {
      let questionError = "";
      for (let i = 0; i < questionArray.length; i++) {
        if (
          questionArray[i].questionMark !== 0 &&
          !questionArray[i].questionMark
        ) {
          questionError = `Please add ${indexPrefix(i + 1)} question's mark`;
          break;
        } else if (
          questionArray[i].questionType === "Radio" &&
          questionArray[i].correctAnswer.length === 0
        ) {
          questionError = `Please select ${indexPrefix(
            i + 1
          )} question's correct answer`;
          break;
        } else if (
          questionArray[i].questionType === "Text" &&
          !questionArray[i].textTypeQuestionAnswer
        ) {
          questionError = `Please select ${indexPrefix(
            i + 1
          )} question's sample answer`;
        }
      }

      if (questionError) {
        errorMessage(questionError);
      } else {
        addTest();
      }
    }
  };

  const addQuestion = () => {
    let array = [...questionArray];
    let data = {
      questionType: "",
      questionTitle: "",
      questionDesc: "",
      questionImage: "",
      questionImageKey: "",
      textQuestionImage: "",
      textQuestionImageKey: "",
      questionAudio: "",
      questionAudioKey: "",
      questionVideo: "",
      isOptionImage: false,
      options: [],
      optionsImageKey: [],
      answerFile: "",
      correctAnswer: [],
      questionMark: "",
      review: "",
      questionText: "",
      radioQuestionType: "",
      textQuestionType: "",
      dragAndDropQuestionType: "",
      answer: [],
      hintText: "",
      mark: "",
      textAudio: "",
      multipleAnswer: [],
      imageCorrectAnswer: "",
      dragDrop: [],
      questionDetailInBottomSheet: "",
      textTypeQuestionAnswer: "",
      dragDropItem: [],
    };
    array.push(data);
    setQuestionsArray(array);
    setEditors((prev) => [
      ...prev,
      { id: array.length - 1, editorState: EditorState.createEmpty() },
    ]);
    setEditorsBottomShit((prev) => [
      ...prev,
      { id: array.length - 1, editorState: EditorState.createEmpty() },
    ]);
    setEditorsHint((prev) => [
      ...prev,
      { id: array.length - 1, editorState: EditorState.createEmpty() },
    ]);
  };

  const onQuestionTextAnswerChange = (event, index) => {
    let array = [...questionArray];
    array[index].textTypeQuestionAnswer = event.target.value.trimStart();
    setQuestionsArray([...array]);
  };

  const onQuestionMarkChange = (event, index) => {
    let array = [...questionArray];
    array[index].questionMark = parseInt(event.target.value);
    setQuestionsArray([...array]);
  };

  const onDescriptionChange = (event, index) => {
    let array = [...questionArray];
    array[index].questionDesc = event.target.value.trimStart();
    setQuestionsArray([...array]);
  };

  const handleMultipleAnswerSelect = (index, itemIndex) => {
    let array = [...questionArray];

    let correctAnswer = array[index].correctAnswer;
    const answerIndex = correctAnswer.indexOf(itemIndex);

    if (answerIndex === -1) {
      correctAnswer.push(parseInt(itemIndex));
    } else {
      correctAnswer.splice(answerIndex, 1);
    }

    array[index].correctAnswer = correctAnswer.sort();

    setQuestionsArray([...array]);
  };

  const onQuestionType = (event, index) => {
    let array = [...questionArray];
    array[index].questionType = event.target.value;
    if (event.target.value === "Drag Drop") {
      array[index].dragDrop = [{ left: "", right: "" }];
    } else {
      array[index].dragDrop = [];
    }
    array[index].textQuestionType = "";
    array[index].dragAndDropQuestionType = "";
    array[index].correctAnswer = [];
    array[index].hintText = "";
    array[index].review = "";
    array[index].mark = "";
    array[index].questionAudio = "";
    array[index].multipleAnswer = [];
    array[index].questionDesc = "";
    array[index].questionImage = "";
    array[index].textQuestionImage = "";
    array[index].imageCorrectAnswer = "";
    setQuestionsArray(array);
  };

  const OnradioQuestionType = (event, index) => {
    let array = [...questionArray];

    if (event.target.value === "Question with image answer") {
      array[index].isOptionImage = true;
    }

    if (
      [
        "Multiple answer",
        "Question with description",
        "Question with image",
      ].includes(event.target.value)
    ) {
      array[index].multipleAnswer = [""];
    } else {
      array[index].multipleAnswer = [];
      array[index].questionImage = "";
      array[index].textQuestionImage = "";
      array[index].textQuestionType = "";
      array[index].correctAnswer = [];
      array[index].questionAudio = "";
      array[index].questionDesc = "";
      array[index].imageCorrectAnswer = "";
    }
    array[index].radioQuestionType = event.target.value;
    setQuestionsArray(array);
  };
  const OnTextQuestionType = (event, index) => {
    let array = [...questionArray];
    array[index].textQuestionType = event.target.value.trimStart();
    array[index].questionAudio = "";
    array[index].multipleAnswer = [""];
    setQuestionsArray(array);
  };

  const OnDragAndDropQuestionType = (event, index) => {
    let array = [...questionArray];
    array[index].dragAndDropQuestionType = event.target.value.trimStart();
    setQuestionsArray(array);
  };

  const onRemoveItem = (index) => {
    let array = [...questionArray];
    const editorArray = [...editors];
    array.splice(index, 1);
    editorArray.splice(index, 1);
    setQuestionsArray([...array]);
    setEditors([...editorArray]);
  };

  const handleAnswerChange = (event, index) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(file); // Read the selected file as a data URL

      const callback = (data) => {
        if (data.success) {
          let array = [...questionArray];
          array[index].questionAudio = data.data.data.Location;
          array[index].questionAudioKey = data.data.data.Key;
          setQuestionsArray([...array]);
        }
      };
      props.uploadFile({ data: formData, callback });
    }
  };
  const onMultipleAnswerChange = (event, index, itemIndex) => {
    let array = [...questionArray];
    array[index].multipleAnswer[itemIndex] = event.target.value.trimStart();
    setQuestionsArray([...array]);
  };

  const onDragDropChange = (event, index, itemIndex, type, dragType) => {
    let array = [...questionArray];
    if (type === "left") {
      array[index].dragDropItem = [];
      array[index].dragDrop[itemIndex].left = event.target.value.trimStart();

      for (let dragItem of array[index]?.dragDrop) {
        dragItem.right = "";
        array[index].dragDropItem.push(dragItem.left);
      }
    } else {
      array[index].dragDrop[itemIndex].right = event.target.value.trimStart();
    }

    setQuestionsArray([...array]);
  };

  const handleQuestionImageChange = (event, index) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(file); // Read the selected file as a data URL

      const callback = (data) => {
        if (data.success) {
          let array = [...questionArray];
          array[index].questionImage = data.data.data.Location;
          array[index].questionImageKey = data.data.data.Key;
          setQuestionsArray([...array]);
        }
      };
      props.uploadFile({ data: formData, callback });
    }
  };

  const handleTextQuestionImageChange = (event, index) => {
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);

      const reader = new FileReader();
      reader.readAsDataURL(file); // Read the selected file as a data URL

      const callback = (data) => {
        if (data.success) {
          let array = [...questionArray];
          array[index].textQuestionImage = data.data.data.Location;
          array[index].textQuestionImageKey = data.data.data.Key;
          setQuestionsArray([...array]);
        }
      };
      props.uploadFile({ data: formData, callback });
    }
  };

  const handleFileSelect = (event, index) => {
    setMultipleUploading(true);
    let array = [...questionArray];
    const files = event.target.files; // Get the first selected file
    if (files.length > 0) {
      const formData = new FormData();
      for (const file of files) {
        formData.append("files", file);
      }

      const callback = (data) => {
        setMultipleUploading(false);
        inputFile.current.value = "";
        if (data.success) {
          for (const item of data?.data?.data) {
            const imageUrl = item.Location;
            const imageKey = item.Key;
            if (array[index]?.options) {
              array[index].options = [...array[index].options, imageUrl];
            } else {
              array[index].options = [imageUrl];
            }

            if (array[index]?.optionsImageKey) {
              array[index].optionsImageKey = [
                ...array[index].optionsImageKey,
                imageKey,
              ];
            } else {
              array[index].optionsImageKey = [imageKey];
            }
            array[index].correctAnswer = [];
            setQuestionsArray([...array]);
          }
        }
      };
      props.uploadMultipleFile({ data: formData, callback });
    }

    setQuestionsArray(array);
  };

  const handleRemoveQuestionImage = (_event, index) => {
    let array = [...questionArray];
    array[index].questionImage = "";

    if (array[index].questionImageKey) {
      const callback = (data) => {
        if (data.success) {
          array[index].questionImageKey = "";
          setQuestionsArray([...array]);
        }
      };

      props.removeFile({ key: array[index].questionImageKey, callback });
    }
  };

  const handleRemoveTextQuestionImage = (_event, index) => {
    let array = [...questionArray];
    array[index].textQuestionImage = "";

    if (array[index].textQuestionImageKey) {
      const callback = (data) => {
        if (data.success) {
          array[index].textQuestionImageKey = "";
          setQuestionsArray([...array]);
        }
      };

      props.removeFile({ key: array[index].textQuestionImageKey, callback });
    }
  };

  const handleAnswerImageRemove = (_event, index, itemIndex) => {
    let array = [...questionArray];
    let optionObj = array[index].options;
    let keyObj = array[index].optionsImageKey;
    const key = keyObj[itemIndex];

    if (key) {
      const callback = (data) => {
        if (data.success) {
          keyObj.splice(itemIndex, 1);
          optionObj.splice(itemIndex, 1);
          array[index].correctAnswer = [];

          setQuestionsArray(array);
        }
      };

      props.removeFile({ key, callback });
    }
  };

  return (
    <>
      <label>Name</label>
      <br />
      <input
        className="form-control"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Enter name"
      />
      <br />
      <label>Package</label>
      <select
        id="exampleInputPassword1"
        className="form-control form-select mb-2"
        value={selectPackageId}
        onChange={(e) => {
          setSelectPackageId(e.target.value);
        }}
      >
        <option value="">Select package </option>
        {packageData.map((value) => {
          return (
            <option
              selected={value._id === selectPackageId}
              key={value._id}
              value={value._id}
            >
              {value.name}
            </option>
          );
        })}
      </select>
      <label>Total Question</label>
      <br />
      <input
        className="form-control  mb-2"
        value={totalQuestion}
        onChange={(e) => setTotalQuestion(e.target.value)}
        placeholder="Enter total question"
      />
      <br />
      <label>Total Time</label>
      <br />
      <input
        className="form-control  mb-2 "
        value={totalTime}
        onChange={(e) => setTotalTime(e.target.value)}
        placeholder="Enter total time"
      />

      <label>Get Mark Payment Amount</label>
      <br />
      <input
        className="form-control  mb-2 "
        value={getMarkAmount}
        onChange={(e) => setGetMarkAmount(e.target.value)}
        placeholder="Enter amount"
      />

      <label>Get Mark Stripe Payment ID</label>
      <br />
      <input
        className="form-control  mb-2 "
        value={getMarkStripePaymentId}
        onChange={(e) => setGetMarkStripePaymentId(e.target.value)}
        placeholder="Enter payment id"
      />

      <label>Get Review Payment Amount</label>
      <br />
      <input
        className="form-control  mb-2 "
        value={getReviewAmount}
        onChange={(e) => setGetReviewAmount(e.target.value)}
        placeholder="Enter amount"
      />

      <label>Get Review Stripe Payment ID</label>
      <br />
      <input
        className="form-control  mb-2 "
        value={getReviewStripePaymentId}
        onChange={(e) => setGetReviewStripePaymentId(e.target.value)}
        placeholder="Enter payment id"
      />

      <>
        <label>Create Question and Answer</label>
        <br />

        {questionArray?.map((item, index) => {
          return (
            <div
              className="card px-2 py-3 mt-2"
              style={{
                border: "1px solid #888",
                marginBottom: "25px",
              }}
              key={index}
            >
              <div
                className="d-flex"
                style={{ justifyContent: "space-between" }}
              >
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => onRemoveItem(index)}
                >
                  Remove this question
                </button>
                <b># {index + 1}</b>
              </div>
              <label className="mb-2 mt-2">Question Type:</label>
              <select
                className="form-control form-select mt-2"
                value={questionArray[index].questionType}
                onChange={(event) => onQuestionType(event, index)}
              >
                <option>Select question type</option>
                {QuestionTypeList.map((item, innerItem) => (
                  <React.Fragment key={innerItem}>
                    <option key={item} value={item}>
                      {item}
                    </option>
                  </React.Fragment>
                ))}
              </select>

              {item.questionType === "Radio" && (
                <>
                  <label className="mb-2 mt-2">Radio Type:</label>

                  <select
                    className="form-control form-select mt-2"
                    value={questionArray[index].radioQuestionType}
                    onChange={(event) => OnradioQuestionType(event, index)}
                  >
                    <option>Select radio question type</option>
                    {radioQuestionList.map((item, innerItem) => (
                      <React.Fragment key={innerItem}>
                        <option key={item} value={item}>
                          {item}
                        </option>
                      </React.Fragment>
                    ))}
                  </select>
                </>
              )}

              {item.questionType === "Text" && (
                <>
                  <label className="mb-2 mt-2">Text Type:</label>

                  <select
                    className="form-control form-select mt-2"
                    value={questionArray[index].textQuestionType}
                    onChange={(event) => OnTextQuestionType(event, index)}
                  >
                    <option>Select text question type</option>
                    {textQuestionList.map((item, innerItem) => (
                      <React.Fragment key={innerItem}>
                        <option key={item} value={item}>
                          {item}
                        </option>
                      </React.Fragment>
                    ))}
                  </select>
                </>
              )}

              {item.questionType === "Drag Drop" && (
                <>
                  <label className="mb-2 mt-2">Drag and Drop Type:</label>

                  <select
                    className="form-control form-select mt-2"
                    value={questionArray[index].dragAndDropQuestionType}
                    onChange={(event) =>
                      OnDragAndDropQuestionType(event, index)
                    }
                  >
                    <option>Select drag and drop question type</option>
                    {dragAndDropQuestionList.map((item, innerItem) => (
                      <React.Fragment key={innerItem}>
                        <option key={item} value={item}>
                          {item}
                        </option>
                      </React.Fragment>
                    ))}
                  </select>
                </>
              )}

              <label className="mb-2 mt-2">Question:</label>

              <Editor
                editorStyle={{
                  border: "1px solid #ddd",
                  borderRadius: "3px",
                  minHeight: "200px",
                }}
                editorState={editors[index].editorState}
                onEditorStateChange={(editorState) => {
                  const updatedEditors = editors.map((editor) =>
                    editor.id === editors[index].id
                      ? { ...editor, editorState }
                      : editor
                  );
                  setEditors(updatedEditors);
                  let array = [...questionArray];
                  const contentState =
                    editors[index].editorState?.getCurrentContent();
                  const rawContentState = convertToRaw(contentState);
                  const html = draftToHtml(rawContentState);
                  array[index].questionTitle = html;
                  setQuestionsArray([...array]);
                }}
              />

              <label className="mb-2 mt-2">Question Mark:</label>
              <input
                type="number"
                min="0"
                className="form-control mt-2"
                value={item.questionMark}
                onChange={(e) => onQuestionMarkChange(e, index)}
                placeholder="Enter question mark here"
              />

              <label className="mb-2 mt-2">Question Detail Bottom Sheet:</label>

              <Editor
                editorStyle={{
                  border: "1px solid #ddd",
                  borderRadius: "3px",
                  minHeight: "200px",
                }}
                editorState={editorsBottomShit[index].editorState}
                onEditorStateChange={(editorState) => {
                  const updatedEditors = editorsBottomShit.map((editor) =>
                    editor.id === editorsBottomShit[index].id
                      ? { ...editor, editorState }
                      : editor
                  );
                  setEditorsBottomShit(updatedEditors);
                  let array = [...questionArray];
                  const contentState =
                    editorsBottomShit[index].editorState?.getCurrentContent();
                  const rawContentState = convertToRaw(contentState);
                  const html = draftToHtml(rawContentState);
                  array[index].questionDetailInBottomSheet = html;
                  setQuestionsArray([...array]);
                }}
              />

              {item.questionType === "Text" && (
                <>
                  {item.textQuestionImage ? (
                    <>
                      <label className="mt-4 mb-2">Question image:</label>
                      <div className="d-flex w-25">
                        <img
                          className="img-fluid"
                          src={item.textQuestionImage}
                          alt="Selected"
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="d-flex">
                        <button
                          style={{
                            fontSize: "20px !important",
                            lineHeight: "10px !important",
                          }}
                          className="btn btn-danger btn-sm my-2"
                          onClick={(event) =>
                            handleRemoveTextQuestionImage(event, index)
                          }
                        >
                          Remove <i className="fa fa-remove" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <label className="mt-2 mb-2">Question image:</label>
                      <input
                        className="w-25"
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleTextQuestionImageChange(e, index)
                        }
                      />
                    </>
                  )}

                  <label className="mb-2 mt-2">Sample Answer:</label>
                  <textarea
                    className="form-control mt-2"
                    style={{ height: "100px" }}
                    value={item.textTypeQuestionAnswer}
                    onChange={(e) => onQuestionTextAnswerChange(e, index)}
                    placeholder="Enter question answer here"
                  ></textarea>
                </>
              )}

              {item.questionType === "Drag Drop" && (
                <>
                  {item.dragDrop.map((itemDrag, itemIndex) => {
                    return (
                      <React.Fragment key={itemIndex}>
                        <div className="row">
                          <div className="col-md-6">
                            <label className="mb-2 mt-2">Left Question:</label>
                            <input
                              type="text"
                              className="form-control mt-2"
                              value={itemDrag.left}
                              onChange={(e) =>
                                onDragDropChange(e, index, itemIndex, "left")
                              }
                              placeholder="Enter left question here"
                            />
                          </div>

                          {item.dragAndDropQuestionType ===
                          "Answer with select" ? (
                            <div className="col-md-6">
                              <label className="mb-2 mt-2">
                                Right question:
                              </label>
                              <select
                                className="form-control form-select mt-2"
                                value={itemDrag.right}
                                onChange={(e) => {
                                  onDragDropChange(
                                    e,
                                    index,
                                    itemIndex,
                                    "right"
                                  );
                                }}
                              >
                                <option>Select answer</option>
                                {item.dragDropItem.map((item, innerItem) => (
                                  <React.Fragment key={innerItem}>
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  </React.Fragment>
                                ))}
                              </select>
                            </div>
                          ) : item.dragAndDropQuestionType ===
                            "Answer with typing" ? (
                            <div className="col-md-6">
                              <label className="mb-2 mt-2">Right answer:</label>

                              <input
                                type="text"
                                className="form-control mt-2"
                                value={itemDrag.right}
                                onChange={(e) => {
                                  onDragDropChange(
                                    e,
                                    index,
                                    itemIndex,
                                    "right",
                                    "typing"
                                  );
                                }}
                                placeholder="Enter answer here"
                              />
                            </div>
                          ) : null}

                          {item.dragDrop.length !== 1 && (
                            <div className="d-flex">
                              <button
                                style={{
                                  fontSize: "20px !important",
                                  lineHeight: "10px !important",
                                }}
                                className="btn btn-danger btn-sm my-2"
                                onClick={() => {
                                  let data = [...questionArray];
                                  let obj = data[index].dragDrop;
                                  obj.splice(itemIndex, 1);

                                  // let item = data[index].dragDropItem;
                                  // item.splice(itemIndex, 1);
                                  // data[index].setDragDropItem = item;

                                  data[index].dragDropItem = [];

                                  for (let dragItem of data[index]?.dragDrop) {
                                    dragItem.right = "";
                                    data[index].dragDropItem.push(
                                      dragItem.left
                                    );
                                  }

                                  setQuestionsArray(data);
                                }}
                              >
                                Remove <i className="fa fa-remove" />
                              </button>
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}

                  <div className="d-flex">
                    <button
                      style={{
                        fontSize: "20px !important",
                        lineHeight: "10px !important",
                      }}
                      className="btn btn-danger btn-sm my-2"
                      onClick={() => {
                        let data = [...questionArray];
                        data[index].dragDrop.push({
                          left: "",
                          right: "",
                        });
                        setQuestionsArray(data);
                      }}
                    >
                      Add more +
                    </button>
                  </div>
                </>
              )}

              {["Question with image"].includes(item.radioQuestionType) && (
                <>
                  {item.questionImage ? (
                    <>
                      <label className="mt-4 mb-2">Question image:</label>
                      <div className="d-flex w-25">
                        <img
                          className="img-fluid"
                          src={item.questionImage}
                          alt="Selected"
                          style={{ width: "100px" }}
                        />
                      </div>
                      <div className="d-flex">
                        <button
                          style={{
                            fontSize: "20px !important",
                            lineHeight: "10px !important",
                          }}
                          className="btn btn-danger btn-sm my-2"
                          onClick={(event) =>
                            handleRemoveQuestionImage(event, index)
                          }
                        >
                          Remove <i className="fa fa-remove" />
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <label className="mt-2 mb-2">Question image:</label>
                      <input
                        className="w-25"
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleQuestionImageChange(e, index)}
                      />
                    </>
                  )}
                </>
              )}

              {item.radioQuestionType === "Question with image answer" && (
                <>
                  {multipleUploading ? (
                    <p style={{ padding: "10px" }}>Uploading...</p>
                  ) : (
                    <>
                      <label className="mt-4 mb-2">
                        Select one or multiple image as answer:
                      </label>
                      <input
                        ref={inputFile}
                        type="file"
                        className="mb-4 mt-4 w-25"
                        multiple
                        accept="image/*"
                        onChange={(event) => handleFileSelect(event, index)}
                      />
                    </>
                  )}
                </>
              )}

              {item.options.map((value, itemIndex) => {
                return (
                  <>
                    <label>Answer:</label>
                    <img
                      key={index}
                      src={value}
                      alt={`${index}`}
                      width="100"
                      height="100"
                    />
                    <>
                      <div className="d-flex">
                        <button
                          style={{
                            fontSize: "20px !important",
                            lineHeight: "10px !important",
                          }}
                          className="btn btn-danger btn-sm my-2"
                          onClick={(event) =>
                            handleAnswerImageRemove(event, index, itemIndex)
                          }
                        >
                          Remove <i className="fa fa-remove" />
                        </button>
                      </div>
                    </>
                  </>
                );
              })}

              {[
                "Multiple answer",
                "Question with description",
                "Question with image",
                "Question with image answer",
              ].includes(item.radioQuestionType) && (
                <>
                  {item.multipleAnswer.map((value, itemIndex) => {
                    return (
                      <React.Fragment key={itemIndex}>
                        <label className="mt-2 mb-2">Options:</label>
                        <input
                          className="form-control"
                          type="text"
                          value={value}
                          onChange={(e) =>
                            onMultipleAnswerChange(e, index, itemIndex)
                          }
                          placeholder="Enter answer here"
                        />
                        {item.multipleAnswer.length !== 1 && (
                          <>
                            <div className="d-flex">
                              <button
                                style={{
                                  fontSize: "20px !important",
                                  lineHeight: "10px !important",
                                }}
                                className="btn btn-danger btn-sm my-2"
                                onClick={() => {
                                  let data = [...questionArray];
                                  let obj = data[index].multipleAnswer;
                                  obj.splice(itemIndex, 1);
                                  setQuestionsArray(data);
                                }}
                              >
                                Remove <i className="fa fa-remove" />
                              </button>
                            </div>
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}

                  {[
                    "Multiple answer",
                    "Question with description",
                    "Question with image",
                  ].includes(item.radioQuestionType) && (
                    <>
                      <div className="d-flex">
                        <button
                          style={{
                            fontSize: "20px !important",
                            lineHeight: "10px !important",
                          }}
                          className="btn btn-danger btn-sm my-2"
                          onClick={() => {
                            let data = [...questionArray];
                            data[index].multipleAnswer.push("");
                            setQuestionsArray(data);
                          }}
                        >
                          Add more +
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}

              {item.radioQuestionType === "Question with description" && (
                <>
                  <label className="mb-2 mt-2">question description:</label>
                  <input
                    className="form-control"
                    type="text"
                    value={item.questionDesc}
                    onChange={(e) => onDescriptionChange(e, index)}
                    placeholder="Enter description"
                  />
                </>
              )}

              {item.textQuestionType === "Question with audio" && (
                <>
                  {item.questionAudio ? (
                    <>
                      <label className="mt-2 mb-2">Audio:</label>
                      <audio key={item} controls>
                        <source src={item.questionAudio} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </>
                  ) : (
                    <>
                      <label className="mt-2 mb-2">Audio:</label>
                      <input
                        className="w-25"
                        type="file"
                        accept="audio/*"
                        onChange={(e) => handleAnswerChange(e, index)}
                      />
                    </>
                  )}
                </>
              )}

              {item.questionType === "Radio" && (
                <>
                  {item.radioQuestionType === "Question with image answer" ? (
                    <>
                      <label className="mb-2 mt-2">Correct answer:</label>
                      {item.options.map((_item, itemIndex) => (
                        <div style={{ display: "flex" }} key={itemIndex}>
                          <label style={{ marginRight: "7px" }}>
                            {itemIndex + 1}
                          </label>
                          <input
                            type="checkbox"
                            checked={
                              questionArray[index].correctAnswer.indexOf(
                                itemIndex
                              ) !== -1
                            }
                            onChange={() =>
                              handleMultipleAnswerSelect(index, itemIndex)
                            }
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <label className="mb-2 mt-2">Correct answer:</label>
                      {item.multipleAnswer.map((_item, itemIndex) => (
                        <div key={itemIndex}>
                          <label style={{ marginRight: "7px" }}>
                            {itemIndex + 1}
                          </label>
                          <input
                            type="checkbox"
                            checked={
                              questionArray[index].correctAnswer.indexOf(
                                itemIndex
                              ) !== -1
                            }
                            onChange={() =>
                              handleMultipleAnswerSelect(index, itemIndex)
                            }
                          />
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
              <label className="mt-2 mb-2">Question Hint:</label>

              <Editor
                editorStyle={{
                  border: "1px solid #ddd",
                  borderRadius: "3px",
                  minHeight: "200px",
                }}
                editorState={editorsHint[index].editorState}
                onEditorStateChange={(editorState) => {
                  const updatedEditors = editorsHint.map((editor) =>
                    editor.id === editorsHint[index].id
                      ? { ...editor, editorState }
                      : editor
                  );
                  setEditorsHint(updatedEditors);
                  let array = [...questionArray];
                  const contentState =
                    editorsHint[index].editorState?.getCurrentContent();
                  const rawContentState = convertToRaw(contentState);
                  const html = draftToHtml(rawContentState);
                  array[index].hintText = html;
                  setQuestionsArray([...array]);
                }}
              />
            </div>
          );
        })}

        <br />
        <div className="d-flex">
          <button
            className="btn btn-sm btn-primary mt-2 mb-2"
            onClick={() => addQuestion()}
          >
            Add questions
          </button>
        </div>
      </>

      <br />

      <Button
        onClick={() => {
          onCheckFields();
        }}
      >
        Add Test
      </Button>
    </>
  );
};

const mapStateToProps = (state) => ({
  visible: state.app.visible,
});
export default connect(mapStateToProps, {
  getAllTest,
  addTest,
  updateTest,
  deleteTest,
  getAllPackage,
  uploadFile,
  removeFile,
  uploadMultipleFile,
})(AddTest);
