import React, { useContext } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { AuthContext } from '../../../contexts/AuthContext';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PublicLayout = (props) => {
  const auth = useContext(AuthContext);
  const { pathname } = useLocation();

  if (
    auth &&
    pathname !== '/privacy-policy' &&
    pathname !== '/terms-and-service'
  ) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <React.Fragment>
      <Header role="" isAuth={auth} />
      <main role="main" className="main">
        <Outlet />
      </main>
      <Footer />
    </React.Fragment>
  );
};

export default PublicLayout;
