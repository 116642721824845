import React from "react";

const Footer = () => {
  return (
    <footer className="footer bg-light">
      <div className="container">
        <span className="text-black">© 2023 Resono. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
