import { createAction } from "redux-actions";

export const GET_ALL_GRADE= "GET_ALL_GRADE";
export const getAllGrade= createAction(GET_ALL_GRADE);

export const ADD_GRADE= "ADD_GRADE";
export const addGrade= createAction(ADD_GRADE);

export const UPDATE_GRADE= "UPDATE_GRADE";
export const updateGrade= createAction(UPDATE_GRADE);

export const DELETE_GRADE= "DELETE_GRADE";
export const deleteGrade= createAction(DELETE_GRADE);